import React from 'react';
import ReactDOM from 'react-dom/client';
import Feedback from "./components/Feedback";

const button = document.getElementById('cd-feedback-widget');
if (!button) {
    console.log('No feedback button found');
} else {
    const root = ReactDOM.createRoot(button);
    console.log('Feedback button found');
    let url: string | undefined;
    const dataUrl = button.getAttribute('data-feedback-url')
    if (dataUrl) {
        url = dataUrl;
    }
    let promptText: string | undefined;
    const dataPromptText = button.getAttribute('data-feedback-prompt-text')
    if (dataPromptText) {
        promptText = dataPromptText;
    }
    let promptHelpText: string | undefined;
    const dataPromptHelpText = button.getAttribute('data-feedback-prompt-help-text')
    if (dataPromptHelpText) {
        promptHelpText = dataPromptHelpText;
    }

    root.render(
        <React.StrictMode>
            <Feedback feedbackUrl={url} promptText={promptText} promptHelpText={promptHelpText}/>
        </React.StrictMode>
    );
}