"use client";
import { useState } from "react";
import { MegaphoneIcon } from "@heroicons/react/24/outline";
import styled from "@emotion/styled";

interface ButtonProps {
  buttonColor?: string;
  textColor?: string;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

const Button = ({ buttonColor, textColor, isOpen, setIsOpen }: ButtonProps) => {
  const [isSlidOut, setIsSlidOut] = useState(false);
  const caption = isOpen ? "X" : "Feedback";

  const right = isSlidOut || isOpen ? 8 : 0;

  const ButtonContainer = styled.div`
    z-index: 50;
    position: fixed;
    bottom: 48px;
    right: ${right}px;
    font-size: 18px;
    line-height: 28px;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  `;

  const leftPadding = isSlidOut || isOpen ? 16 : 8;
  const rightPadding = isSlidOut || isOpen ? 16 : 8;
  const rightRadius = isSlidOut || isOpen ? 4 : 0;

  const StyledButton = styled.button<{
    backgroundColor?: string;
    fontColor?: string;
  }>`
    background-color: ${(props) =>
      props.backgroundColor || "rgb(59, 130, 246)"};
    border-radius: 4px ${rightRadius}px ${rightRadius}px 4px;
    border: none;
    color: rgb(255, 255, 255);
    font-size: 18px;
    line-height: 36px;
    padding: 8px ${rightPadding}px 8px ${leftPadding}px;
    display: flex;
    cursor: pointer;
  `;

  return (
    <ButtonContainer>
      <StyledButton
        backgroundColor={buttonColor}
        fontColor={textColor}
        onClick={() => {
          setIsOpen(!isOpen);
          if (isOpen) setIsSlidOut(false);
        }}
        onMouseLeave={() => {
          if (!isOpen && isSlidOut) setIsSlidOut(false);
        }}
        onMouseEnter={() => {
          if (!isSlidOut) setIsSlidOut(true);
        }}
        onBlur={() => {
          if (!isOpen && isSlidOut) setIsSlidOut(false);
        }}
      >
        {isSlidOut || isOpen ? (
          caption
        ) : (
          <MegaphoneIcon style={{ height: "36px", width: "36px" }} />
        )}
      </StyledButton>
    </ButtonContainer>
  );
};

export default Button;
