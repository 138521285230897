"use client";
import { useEffect, useState } from "react";
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  MegaphoneIcon,
} from "@heroicons/react/24/outline";
import styled from "@emotion/styled";

interface FeedbackFormProps {
  reset: boolean;
  setReset: (reset: boolean) => void;
  defaultRows?: number;
  feedbackUrl: string;
  promptText?: string;
  promptHelpText?: string;

  // debugging props
  initError?: string;
  initSending?: boolean;
  initSent?: boolean;
}

const FormContainer = styled.div<{
  backgroundColor?: string;
  borderColor?: string;
}>`
  background-color: ${(props) => props.backgroundColor || "rgb(229, 231, 235)"};
  padding: 16px;
  border-radius: 8px;
  max-width: 256px;
  border-width: 1px;
  border-color: ${(props) => props.borderColor || "rgb(3, 7, 18)"};
  font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
`;

const FeedbackSendingDiv = styled.div`
  background-color: rgb(29, 78, 216);
  color: rgb(255, 255, 255);
  font-size: 16px;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  margin-bottom: 16px;
`;

const FeedbackSentDiv = styled.div`
  background-color: rgb(21, 128, 61);
  color: rgb(255, 255, 255);
  font-size: 16px;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  margin-bottom: 16px;
`;

const ErrorDiv = styled.div`
  background-color: rgb(185, 28, 28);
  color: rgb(255, 255, 255);
  font-size: 16px;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  margin-bottom: 16px;
  place-items: center;
`;

const PromptHelpText = styled.div`
  color: rgb(55, 65, 81);
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
`;

const PromptInput = styled.textarea`
  background-color: rgb(249, 250, 251);
  border-width: 1px;
  border-color: rgb(209, 213, 219);
  color: rgb(17, 24, 39);
  font-size: 14px;
  line-height: 20px;
  border-radius: 8px;
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
`;

const EmailInput = styled.input`
  background-color: rgb(249, 250, 251);
  border-width: 1px;
  border-color: rgb(209, 213, 219);
  color: rgb(17, 24, 39);
  font-size: 14px;
  line-height: 20px;
  border-radius: 8px;
  display: block;
  width: 100%;
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 20px;
`;

const LabelParent = styled.div`
  margin-bottom: 20px;
  line-height: 20px;
`;

const CheckboxLabel = styled.label`
  color: rgb(17, 24, 39);
  cursor: pointer;
`;

const CheckboxText = styled.span`
  margin-left: 8px;
  font-size: 14px;
  line-height: 20px;
`;

const SubmitButton = styled.button`
  background-color: rgb(59, 130, 246);
  border-radius: 8px;
  border: none;
  color: rgb(255, 255, 255);
  font-size: 16px;
  line-height: 24px;
  padding: 10px 20px;
  display: block;
  width: 100%;
  cursor: pointer;
`;

const CheckBox = styled.input`
  cursor: pointer;
`;

const FeedbackForm = ({
  reset,
  setReset,
  defaultRows = 3,
  feedbackUrl,
  promptText = "Enter your feedback here...",
  promptHelpText,
  initError = "",
  initSending = false,
  initSent = false,
}: FeedbackFormProps) => {
  const [message, setMessage] = useState("");
  const [wantsResponse, setWantsResponse] = useState(false);
  const [email, setEmail] = useState("");
  const [feedbackSending, setFeedbackSending] = useState(initSending);
  const [feedbackSent, setFeedbackSent] = useState(initSent);
  const [error, setError] = useState(initError);
  const url = !!window ? window.location.href : "";

  useEffect(() => {
    if (reset) {
      setMessage("");
      setWantsResponse(false);
      setEmail("");
      setFeedbackSent(initSent);
      setFeedbackSending(initSending);
      setError(initError);
      setReset(false);
    }
  }, [reset, setReset, initError, initSent, initSending]);

  const handleResetError = () => {
    if (!!error) setError("");
  };

  const onSubmit = async () => {
    console.log(`Sending feedback to ${feedbackUrl}`, {
      email,
      message,
      wantsResponse,
      url,
    });
    if (!message) {
      setError("Please enter feedback");
      return;
    }

    if (wantsResponse && !email) {
      setError("Please enter an email address if you would like a response");
      return;
    }

    setFeedbackSending(true);

    try {
      const request = new Request(feedbackUrl, {
        method: "POST",
        body: JSON.stringify({ email, message, wantsResponse, url }),
        headers: new Headers({
          "Content-Type": "application/json",
        }),
      });
      const response = await fetch(request);
      if (!response.ok) {
        const responseText = await response.text();
        throw new Error(
          `Error sending feedback -- status: ${response.status}, message: ${responseText}`,
        );
      }
      console.log("Response", response);
      setFeedbackSent(true);
    } catch (e) {
      console.error("Error sending feedback", e);
      setError("Error sending feedback");
    }
    setFeedbackSending(false);
  };

  return (
    <FormContainer>
      {feedbackSending && (
        <FeedbackSendingDiv>
          <MegaphoneIcon
            style={{
              height: "28px",
              width: "28px",
              marginRight: "8px",
              flexShrink: 0,
            }}
          />
          Sending feedback...
        </FeedbackSendingDiv>
      )}
      {feedbackSent && (
        <FeedbackSentDiv>
          <CheckCircleIcon
            style={{
              height: "28px",
              width: "28px",
              marginRight: "8px",
              flexShrink: 0,
            }}
          />
          Feedback submitted!
        </FeedbackSentDiv>
      )}
      {error && (
        <ErrorDiv>
          <ExclamationTriangleIcon
            style={{
              height: "28px",
              width: "28px",
              marginRight: "8px",
              flexShrink: 0,
            }}
          />
          {error}
        </ErrorDiv>
      )}
      {!feedbackSent && (
        <div>
          {!!promptHelpText && (
            <PromptHelpText>{promptHelpText}</PromptHelpText>
          )}
          <PromptInput
            placeholder={promptText}
            value={message}
            rows={defaultRows}
            onChange={(e) => {
              e.preventDefault();
              handleResetError();
              setMessage(e.target.value);
            }}
          />
          <LabelParent>
            <CheckboxLabel>
              <CheckBox
                type="checkbox"
                checked={wantsResponse}
                onChange={() => {
                  handleResetError();
                  setWantsResponse(!wantsResponse);
                }}
              />
              <CheckboxText>
                I would like to be contacted about my feedback
              </CheckboxText>
            </CheckboxLabel>
          </LabelParent>
          {wantsResponse && (
            <div>
              <EmailInput
                type="text"
                placeholder="Email"
                value={email}
                onChange={(e) => {
                  handleResetError();
                  setEmail(e.target.value);
                }}
              />
            </div>
          )}
          <SubmitButton
            onClick={async () => {
              await onSubmit();
            }}
          >
            Submit Feedback
          </SubmitButton>
        </div>
      )}
    </FormContainer>
  );
};

export default FeedbackForm;
