"use client";
import { useState } from "react";
import Button from "../Button";
import FeedbackForm from "../FeedbackForm";
import styled from "@emotion/styled";

interface FeedbackProps {
  feedbackUrl?: string;
  promptText?: string;
  promptHelpText?: string;
  startOpen?: boolean;
  feedbackPromptLines?: number;
}

const Feedback = ({
  feedbackUrl = "https://92d7caucvd.execute-api.us-east-1.amazonaws.com/default/feedback-api",
  promptText,
  promptHelpText,
  startOpen = false,
  feedbackPromptLines,
}: FeedbackProps) => {
  const [isOpen, setIsOpen] = useState(startOpen);
  const [resetForm, setResetForm] = useState(false);

  const setIsOpenWrapper = (value: boolean) => {
    setIsOpen(value);
    if (!value) {
      setResetForm(true);
    }
  };

  const FormContainer = styled.div`
    z-index: 50;
    position: fixed;
    bottom: 108px;
    right: 16px;
  `;

  return (
    <div>
      {isOpen && (
        <FormContainer>
          <FeedbackForm
            reset={resetForm}
            setReset={setIsOpenWrapper}
            feedbackUrl={feedbackUrl}
            promptHelpText={promptHelpText}
            promptText={promptText}
            defaultRows={feedbackPromptLines}
          />
        </FormContainer>
      )}
      <Button isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};

export default Feedback;
